<template>
  <div class="">
    <div @click="isOpen = !isOpen" class="header-wrap p-1 px-2">
      <h6>{{ title }}</h6>
      <fa :icon="['fas', chosenIcon]" />
    </div>
    <template v-if="isOpen">
      <div
        v-for="(option, i) in options"
        :key="option"
        class="content d-flex flex-wrap align-items-center gap-1 px-2"
      >
        <input
          @change="changeFilter(option)"
          type="checkbox"
          :id="subTitle + i"
          :checked="isCheckedAvailable(changeName(option))"
        />
        <label :for="subTitle + i" class="fw-bold">{{
          changeName(option)
        }}</label>
        <p class="ms-auto">({{ option.count }})</p>
        <div v-if="option.subcategories" class="w-100 sub">
          <sub-category
            v-for="sub in option.subcategories"
            :key="sub"
            :sub="sub"
            :allFilter="isAvailable"
            @updateCategory="getUpdate($event, option)"
          ></sub-category>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SubCategory from "./SubCategory.vue";
// import BaseInput from "./BaseInput.vue";
export default {
  props: ["title", "options", "opened", "isAvailable"],
  emits: ["input"],
  components: {
    // BaseInput,
    SubCategory,
  },
  data() {
    return {
      isOpen: this.opened ? true : false,
      allValues: "",
    };
  },
  computed: {
    ischangedCategory() {
      return this.$store.state.isCategory;
    },
    allCategory() {
      const arr = this.$store.state.category.split("^^").filter(Boolean);
      return arr;
    },
    chosenIcon() {
      return this.isOpen ? "minus" : "angle-right";
    },
    subTitle() {
      return this.title.slice(0, 3);
    },
  },
  methods: {
    isCheckedAvailable(val) {
      if (!this.isAvailable.length) {
        return;
      } else {
        let arr = this.isAvailable.split("^^").filter(Boolean);
        return arr.includes(val);
      }
    },
    callAgain(arr, str = "") {
      arr.forEach((el) => {
        str += el.name + "^^";
        if (el.subcategories) {
          str += this.callAgain(el.subcategories, "");
        }
      });
      return str;
    },
    changeFilter(val) {
      if (this.title == "Category") {
        let a = val.name + "^^";
        if (val.subcategories) {
          let subNames = this.callAgain(val.subcategories);
          this.allValues = a + subNames;
        }
        this.$store.commit("changeCategory", this.allValues);
        this.$emit("input", { all: this.allValues, value: val.name });
        // this.$emit("input", val.name);
        return;
      }
      this.$emit("input", val);
    },
    getUpdate(val, opt) {
      this.$store.commit("changeCategory", val.all);
      this.$emit("input", { all: opt.name, value: val.value });
      // console.log(opt.name + "^^" + val);
      // alert(val, opt);
    },
    changeName(val) {
      let arr = Object.keys(val);
      arr = arr.filter((item) => item != "id" && item != "count");
      return val[arr[0]];
    },
  },
  watch: {
    // ischangedCategory() {
    //   let a = this.allCategory.includes(this.sub.name);
    //   if (a) {
    //     console.log("done");
    //     this.category = false;
    //   }
    // },
  },
};
</script>

<style scoped>
.header-wrap {
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}
.sub {
  margin-top: -4px;
}
h6 {
  font-size: 0.85rem;
  font-weight: 501;
  user-select: none;
}
h6,
p {
  font-size: 0.9rem;
}
label {
  font-size: 0.85rem;
  user-select: none;
}
</style>
