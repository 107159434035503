<template>
  <div v-if="search">
    <div @click="isOpen = !isOpen" class="header-wrap p-1 px-2">
      <h6>{{ title }}</h6>
      <fa :icon="['fas', chosenIcon]" />
    </div>
    <div v-if="isOpen" class="search-input d-flex gap-2 p-1 px-2">
      <input type="text" v-model="text" :placeholder="placeholder1" />
      <button @click="getText" :disabled="!text">Search</button>
    </div>
  </div>
  <div v-else class="no-search">
    <div @click="isOpen = !isOpen" class="header-wrap p-1 px-2">
      <h6>{{ title }}</h6>
      <fa :icon="['fas', chosenIcon]" />
    </div>
    <div
      v-if="isOpen"
      @click="isEmpty = false"
      class="search-input d-flex gap-2 p-2 w-100"
    >
      <!-- <base-input v-model="content"></base-input> -->
      <input
        type="number"
        v-model="min"
        pattern="^[0-9]"
        :placeholder="placeholder1"
      />-
      <input
        type="number"
        v-model="max"
        pattern="^[0-9]"
        :placeholder="placeholder2"
      />
      <button @click.stop="submit" :disabled="isFull">Search</button>
    </div>
  </div>
</template>

<script>
// import BaseInput from "./BaseInput.vue";
import { checkPositiveNumber } from "../../utils";
export default {
  props: [
    "opened",
    "search",
    "isAvailable",
    "isInput",
    "title",
    "placeholder1",
    "placeholder2",
  ],
  emits: ["input"],
  components: {
    // BaseInput,
  },
  data() {
    return {
      isOpen: this.opened ? true : false,
      min: "",
      max: "",
      text: "",
    };
  },
  computed: {
    chosenIcon() {
      return this.isOpen ? "minus" : "angle-right";
    },
    isFull() {
      return this.min.length == 0 || this.max.length == 0;
    },
  },
  methods: {
    getText() {
      this.$emit("input", this.text);
    },
    submit() {
      this.$emit("input", { min: this.min, max: this.max });
    },
  },
  watch: {
    isInput(val) {
      if (!val) this.text = "";
    },
    isAvailable: {
      handler(val) {
        if (!val?.min && !val?.max) {
          this.min = "";
          this.max = "";
        }
      },
      deep: true,
    },
    min(val) {
      if (checkPositiveNumber(val)) {
        this.min = val;
      } else {
        this.min = "";
      }
    },
    max(val) {
      if (checkPositiveNumber(val)) {
        this.max = val;
      } else {
        this.max = "";
      }
    },
  },
};
</script>

<style scoped>
.header-wrap {
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}
h6 {
  font-size: 0.85rem;
  font-weight: 501;
  user-select: none;
}
h6,
p,
input::placeholder {
  font-size: 0.9rem;
}
label {
  user-select: none;
}
.search-input {
  padding: 10px 8px;
}
.search-input input,
button {
  height: 24px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  outline: none;
}
.search-input input {
  flex: 3;
}
.search-input button {
  line-height: 12px;
  flex: 1;
}
button:hover {
  background: #ddd;
}
.no-search input,
.no-search button {
  width: 30%;
}
</style>
