<template>
  <div :style="nodeMargin">
    <div
      v-if="sub.count"
      class="content d-flex flex-wrap align-items-center gap-1"
    >
      <input
        v-model="category"
        @change="updateC(sub)"
        :id="sub.name"
        type="checkbox"
        :checked="checkAva(sub.name)"
      />
      <label :for="sub.name" :class="{ 'fw-bold': sub.subcategories }">{{
        sub.name
      }}</label>
      <p class="ms-auto">({{ sub.count }})</p>
    </div>
    <div v-if="sub.subcategories && sub.subcategories?.length">
      <sub-category
        v-for="node in sub.subcategories"
        :key="node"
        :sub="node"
        :spacing="4"
        @hello="getG"
        :parent="category"
        :allFilter="allFilter"
      ></sub-category>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sub", "spacing", "parent", "allFilter"],
  emits: ["updateCategory", "hello"],
  data() {
    return {
      category: false,
      isClicked: false,
    };
  },
  computed: {
    ischangedCategory() {
      return this.$store.state.isCategory;
    },
    nodeMargin() {
      return {
        "margin-left": `${this.spacing}px`,
      };
    },
    allCategory() {
      const arr = this.$store.state.category.split("^^").filter(Boolean);
      return arr;
    },
  },
  methods: {
    updateC(val) {
      this.$store.commit("changeCategory", val.name);
      this.$emit("updateCategory", { all: "", value: val.name });
    },
    checkAva(val) {
      if (!this.allFilter.length) {
        return;
      } else {
        let arr = this.allFilter.split("^^").filter(Boolean);
        return arr.includes(val);
      }
    },
    getG(val) {
      this.$emit("updateCategory", {
        all: this.sub.name + "^^" + val.name,
        value: val.name,
      });
    },
  },
  watch: {
    category() {
      this.$emit("hello", this.sub);
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: -4px;
  padding-left: 4px;
}
h6 {
  font-size: 0.85rem;
  font-weight: 501;
  user-select: none;
}
h6,
p {
  font-size: 0.9rem;
}
label {
  font-size: 0.85rem;
  user-select: none;
}
</style>
